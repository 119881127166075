import { Link, usePage } from '@inertiajs/react';
import { Box, Button, Container, Grid, rem, SimpleGrid, Stack, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCircleCheckFilled, IconProgress } from '@tabler/icons-react';

import SalesProgress from '@/components/SalesProgress.jsx';

function Stage({ title, done = false }) {
  if (done) {
    return (
      <Link href={route('site.account.profile')}>
        <StageDone title={title} />
      </Link>
    );
  }
  return (
    <Link href={route('site.account.profile')}>
      <StagePending title={title} />
    </Link>
  );
}

function StageDone({ title }) {
  return (
    <Stack gap={5} align={'center'}>
      <Box c={'gf'} p={0} m={0} h={rem(30)}>
        <IconCircleCheckFilled stroke={1.5} size="2rem" />
      </Box>
      <Text fw={'bold'} fz={rem(14)} c={'gf'}>
        {title}
      </Text>
    </Stack>
  );
}

function StagePending({ title }) {
  return (
    <Stack gap={5} align={'center'}>
      <Box c={'white'} p={0} m={0} h={rem(30)}>
        <IconProgress stroke={1.5} size="2rem" />
      </Box>
      <Text fz={rem(14)} c={'white'}>
        {title}
      </Text>
    </Stack>
  );
}

export default function AccountProgress() {
  const user = usePage().props?.auth?.user;
  if (!user) {
    return <SalesProgress />;
  }

  if (user.has_email_verified && user.has_phone_verified) {
    return <SalesProgress />;
  }

  // is mobile var mantine way
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Box bg={'gfdark.9'} c={'white'}>
      <Container>
        <Grid justify="center" align="center" gutter={0} pb={isMobile ? 'lg' : 0}>
          {/* Greeting */}
          <Grid.Col span={{ sm: 4 }}>
            <Box py={'xl'}>
              <Text lh={1} fz={rem(24)}>
                Salam {user.name},
              </Text>
              <Text lh={1.5} fz={rem(32)}>
                {"What's next for you?"}
              </Text>
              <Button component={Link} href={route('site.account.profile')} size="md" mt={'lg'} c={'white'}>
                Complete Your Profile
              </Button>
            </Box>
          </Grid.Col>
          {/* Stages */}
          <Grid.Col span={{ sm: 6 }}>
            <SimpleGrid cols={{ base: 3, md: 4 }} ta={'center'}>
              <Stage done title="Account Creation" />
              <Stage done={user.has_email_verified} title="E-Mail Verification" />
              <Stage done={user.has_phone_verified} title="Phone Verification" />
              {/*<Stage title="Top-up Your Wallet" />*/}
            </SimpleGrid>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
}
