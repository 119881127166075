import { Head, usePage } from '@inertiajs/react';
import {
  Button,
  Card,
  Checkbox,
  Combobox,
  Container,
  createTheme,
  Grid,
  MantineProvider,
  Menu,
  Notification,
  Paper,
  rem,
  Switch,
  Text,
  Tooltip,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications, notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import AccountProgress from '@/components/AccountProgress.jsx';
import SalesProgress from '@/components/SalesProgress.jsx';

import classes from './SiteLayout.module.css';
import SiteNav from './SiteNav.jsx';

const gf = ['#fff8e1', '#ffefcc', '#ffdd9b', '#ffca64', '#ffba38', '#ffb01b', '#ffab09', '#e39600', '#ca8500', '#af7100'];
const gfdark = ['#e8ecee', '#d1d8dd', '#bbc5cc', '#a4b1bb', '#8d9eaa', '#768a98', '#5f7787', '#496376', '#325065', '#1b3c54'];

const themeOverride = createTheme({
  colors: { gf, gfdark },
  other: {
    headerHeight: rem(60),
  },
  fontFamily: 'Inria Sans',
  primaryColor: 'gf',
  cursorType: 'default',
  defaultRadius: 'xs',
  components: {
    Checkbox: Checkbox.extend({ styles: { label: { userSelect: 'none' }, description: { cursor: 'text' } } }),
    Switch: Switch.extend({ styles: { root: { userSelect: 'none' } } }),
    Grid: Grid.extend({ defaultProps: { overflow: 'visible' } }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
        transitionProps: { transition: 'pop', duration: 100 },
      },
    }),
    Container: Container.extend({
      defaultProps: { size: 'lg' },
    }),
    Button: Button.extend({
      classNames: classes,
      defaultProps: {
        variant: 'outline-gf',
      },
    }),
    Card: Card.extend({
      defaultProps: {},
    }),
    Paper: Paper.extend({
      defaultProps: { shadow: 'xs' },
    }),
    Menu: Menu.extend({
      defaultProps: {},
    }),
    Combobox: Combobox.extend({
      defaultProps: { shadow: 'md' },
    }),
    Text: Text.extend({
      defaultProps: {
        size: 'sm',
      },
    }),
    Notification: Notification.extend({
      defaultProps: {},
    }),
  },
});

const resolver = _theme => ({
  variables: {
    '--color-main': '#1b3c54',
    '--color-dim': '#768a98',
    '--color-gf': '#ffab09',
    '--header-height': '60px',
    '--footer-height': '490px',
  },
});

export default function SiteLayout({ showFooter = true, children }) {
  const { flash, auth } = usePage().props;

  // Show flash message notifications
  if (flash?.message) {
    notifications.show({
      message: flash.message,
      color: flash.color,
    });
  }

  // Show email verification notification
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    if (params.get('verified')) {
      notifications.show({
        message: 'Your email has been verified!',
        color: 'green',
      });
    }
  }, []);

  const gaId = import.meta.env?.VITE_GA_ID;
  if (gaId) {
    ReactGA.initialize(gaId, { gaOptions: { userId: auth?.user?.id } });
  }

  const gtmId = import.meta.env?.VITE_GTM_ID;
  if (gtmId) {
    TagManager.initialize({ gtmId: gtmId });
  }

  const pixelId = import.meta.env?.VITE_PIXEL_ID;
  if (pixelId) {
    ReactPixel.init(
      pixelId,
      { em: auth?.user?.email },
      {
        autoConfig: true,
        debug: false,
      },
    );
    ReactPixel.pageView();
  }

  return (
    <>
      <Head>
        <meta
          head-key="description"
          name="description"
          content="Goldframer is revolutionizing art investment by making it easy to own a piece of exquisite Islamic art. Explore our curated selection of artworks and start investing in the rich heritage of Islamic culture today."
        />
      </Head>

      <MantineProvider withCSSVariables cssVariablesResolver={resolver} theme={themeOverride}>
        <ModalsProvider>
          <SiteNav showFooter={showFooter}>
            <AccountProgress />
            {children}
          </SiteNav>
        </ModalsProvider>
        <Notifications position={'top-center'} />
      </MantineProvider>
    </>
  );
}

export function createSiteLayout(page, props) {
  return <SiteLayout {...props}>{page}</SiteLayout>;
}
